<template>
  <TarjetaDatos titulo="PRODUCTO FLEXIBLE" icon="pencil">
    <div v-if="!flexible.producto || flexible.producto.TextoTarifaGrupo == null">
      <AuxInput title="Tarifa">
        <v-select
          placeholder="Tarifa"
          item-text="TextoTarifa"
          :items="[flexible.tarifa]"
          readonly
          return-object
          filled
          v-model="flexible.tarifa"
          dense
          hide-details="auto"
        ></v-select>
      </AuxInput>
      <v-divider></v-divider>
      <AuxInput title="Consumo total anual">
        <v-text-field
          placeholder="Consumo total anual"
          filled
          v-model="flexible.consumoAnualSips"
          readonly
          dense
          hide-details="auto"
          suffix="KWh/año"
        ></v-text-field>
      </AuxInput>
      <v-divider></v-divider>
      <AuxInput title="Costes operativos">
        <v-text-field
          placeholder="Costes operativos"
          filled
          v-model="flexible.costesOperativos"
          dense
          hide-details="auto"
          suffix="€/MWh"
        ></v-text-field>
      </AuxInput>
      <v-divider></v-divider>
      <div class="d-flex">
        <AuxInput v-if="$root.acceso('VER_COMISIONES_CONTRATOS')" title="Comisión">
          <v-chip color="secondary" outlined class="v-chip--active" label>{{ comision | eur }}</v-chip>
        </AuxInput>
        <AuxInput title="Término variable">
          <v-chip label outlined>{{ terminoVariableGAS }} x MIBGAS + {{ terminoVariableKGAS }} €/MWh</v-chip>
        </AuxInput>
        <AuxInput title="Término fijo">
          <v-chip label outlined>{{ parseFloat(terminoFijoGAS / 365).toFixed(6) * 1 }} €/Día</v-chip>
        </AuxInput>
      </div>
    </div>
    <div v-else-if="(flexible.producto && flexible.producto.TextoTarifaGrupo)">
      <v-card-title>
        <v-spacer />
        <h3>Ya tienes un producto {{ flexible.producto.TextoTarifaGrupo.includes('FLEXIBLE') ? 'flexible' : '' }} seleccionado</h3>
        <v-spacer />
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          @click="
            () => {
              $root.$emit('snack', `Producto deseleccionado`);
              flexible.producto = null;
            }
          "
        >Generar {{ flexible.producto.TextoTarifaGrupo.includes('FLEXIBLE') ? 'otro' : 'un' }} producto flexible</v-btn>
        <v-spacer />
      </v-card-actions>
    </div>
  </TarjetaDatos>
</template>

<script>

export default {
  components: {
    TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
    AuxInput: () => import('@/components/AuxInput.vue')
  },
  props: {
    value: Object,
  },
  data() {
    return {
      terminoFijoGAS: null,
      terminoVariableGAS: null,
      terminoVariableKGAS: null,
    }
  },
  computed: {
    flexible: {
      get() { return this.value },
      set(v) { this.$emit('input', v) }
    },
    comision() {
      if (!this.$root.acceso('VER_COMISIONES_CONTRATOS')) return ''
      return this.flexible.costesOperativos * 0.7 * this.flexible.consumoAnualSips / 1000
    }
  },
  watch: {
    "flexible.tarifa": {
      async handler(v) {
        const { data } = await axios({
          method: 'GET',
          url: `${process.env.VUE_APP_OUR_API_URL}/altaproducto.php`,
          params: {
            tarifa: v.TextoTarifa
          }
        })

        this.terminoFijoGAS = parseFloat(data.terminoFijoGAS).toFixed(3) * 1
        this.terminoVariableGAS = parseFloat(data.terminoVariableGAS).toFixed(6) * 1
        this.terminoVariableKGAS = (data.terminoVariableKGAS)
      },
      immediate: true
    }
  }
}
</script>